// Sass example
$title-color: white;

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

header {
  background-color: #222;
  padding: 1rem;
  text-align: center;
}

h1 {
  font-size: 1.5rem;
  color: $title-color;
}

.logo {
  animation: logo-spin infinite 20s linear;
  height: 80px;
}

main {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 48px;
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

pre {
  white-space: pre-wrap;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
